<template>
    <eventsLayout>
        <div class="page_create_event">
            <v-container>
                <h1>New Event</h1>
                <section>

                    <!-- Event Name -->
                    <div class="row_section">
                        <div class="_name">
                            Name
                        </div>
                        <div class="_data">
                            <input type="text" class="input-style-2" :class="{'input_error': $v.name.$error}"
                                   placeholder="Name of the event"
                                   required
                                   v-model="name"
                                   @input="$v.name.$touch()"
                                   @blur="$v.name.$touch()">
                            <div class="error-msg" v-if="$v.name.$error">*Required</div>
                        </div>
                    </div>

                    <!-- Genre -->
                    <div class="row_section">
                        <div class="_name">
                            Genre
                        </div>
                        <div class="_data checkboxes border_bottom">
                            <div class="material_checkbox">
                                <input type="checkbox" id="Techno" value="Techno" v-model="genres">
                                <label for="Techno">Techno</label>
                            </div>
                            <div class="material_checkbox">
                                <input type="checkbox" id="House" value="House" v-model="genres">
                                <label for="House">House</label>
                            </div>
                            <div class="material_checkbox">
                                <input type="checkbox" id="Trance" value="Trance" v-model="genres">
                                <label for="Trance">Trance</label>
                            </div>
                            <div class="material_checkbox">
                                <input type="checkbox" id="Psytrance" value="Psytrance" v-model="genres">
                                <label for="Psytrance">Psytrance</label>
                            </div>
                            <div class="material_checkbox">
                                <input type="checkbox" id="DrumBass" value="DrumBass" v-model="genres">
                                <label for="DrumBass">Drum & Bass</label>
                            </div>
                            <div class="material_checkbox">
                                <input type="checkbox" id="Breakbeat" value="Breakbeat" v-model="genres">
                                <label for="Breakbeat">Breakbeat</label>
                            </div>
                            <div class="material_checkbox">
                                <input type="checkbox" id="HardDance" value="HardDance" v-model="genres">
                                <label for="HardDance">Hard Dance</label>
                            </div>
                            <div class="material_checkbox">
                                <input type="checkbox" id="Dubstep" value="Dubstep" v-model="genres">
                                <label for="Dubstep">Dubstep</label>
                            </div>
                            <div class="material_checkbox">
                                <input type="checkbox" id="Downtempo" value="Downtempo" v-model="genres">
                                <label for="Downtempo">Downtempo</label>
                            </div>
                            <div class="error-msg err-checkbox" v-if="$v.genres.$error">*Required</div>
                        </div>

                    </div>


                    <!-- Setting -->
                    <div class="row_section">
                        <div class="_name">
                            Setting
                        </div>
                        <div class="_data checkboxes">
                            <div class="material_checkbox">
                                <input type="checkbox" id="Indoor" value="Indoor" v-model="settings">
                                <label for="Indoor">Indoor</label>
                            </div>
                            <div class="material_checkbox">
                                <input type="checkbox" id="Outdoor" value="Outdoor" v-model="settings">
                                <label for="Outdoor">Outdoor</label>
                            </div>
                            <div class="material_checkbox">
                                <input type="checkbox" id="Online" value="Online" v-model="settings">
                                <label for="Online">Online</label>
                            </div>
                            <div class="error-msg err-checkbox" v-if="$v.settings.$error">*Required</div>
                        </div>
                    </div>

                    <!-- Location -->
                    <div class="row_section">
                        <div class="_name">
                            Location
                        </div>
                        <div class="_data">
                            <vue-google-autocomplete
                                id="location"
                                classname="input-style-2"
                                placeholder="Address"
                                types="(cities)"
                                v-on:keyup="keypressLocation"
                                v-on:placechanged="getAddressData">
                            </vue-google-autocomplete>
                            <!--                        <div class="hint_message_error">-->
                            <!--                            <span v-if="!$v.location.isNameValid">Only alphanumeric characters, hyphen and space</span>-->
                            <!--                            <span v-if="!$v.location.maxLength">200 characters max</span>-->
                            <!--                        </div>-->
                            <!--                        <input type="text" class="input-style-2" :class="{'input_error': $v.location.$error}"-->
                            <!--                               placeholder="Address"-->
                            <!--                               required-->
                            <!--                               v-model="location"-->
                            <!--                               @input="$v.location.$touch()"-->
                            <!--                               @blur="$v.location.$touch()">-->
                            <div class="error-msg" v-if="$v.location.$error">*Required</div>
                        </div>
                    </div>

                    <!-- Date / Time -->
                    <div class="row_section">
                        <div class="_name">
                            Date / Time
                        </div>
                        <div class="_data date_pickers">
                            <div class="date_time_begins">
                                <div class="date begins">
                                    <v-menu
                                        ref="menuDateBegin"
                                        v-model="menuDateBegin"
                                        :close-on-content-click="false"
                                        :return-value.sync="date.beginDate"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div class="wrap_input_" v-bind="attrs" v-on="on">
                                                <input type="text" class="input-style-2 dropdown" :class="{'input_error': $v.date.beginDate.$error}"
                                                       v-model="beginDateFormatted"
                                                       :disabled="true"
                                                       placeholder="Begins">
                                            </div>
                                        </template>
                                        <v-date-picker
                                            v-model="date.beginDate"
                                            :weekday-format="getDay"
                                            no-title
                                            scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="menuDateBegin = false">
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.menuDateBegin.save(date.beginDate)">
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                    <div class="error-msg" v-if="$v.date.beginDate.$error">*Required</div>
                                </div>
                                <div class="time begins">
                                    <v-menu
                                        ref="menuTimeBegin"
                                        v-model="menuTimeBegin"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        :return-value.sync="date.beginTime"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div class="wrap_input_" v-bind="attrs" v-on="on">
                                                <input type="text" class="input-style-2 dropdown" :class="{'input_error': $v.date.beginTime.$error}"
                                                       :disabled="true"
                                                       v-model="date.beginTime"
                                                       placeholder="Time">
                                            </div>
                                        </template>
                                        <v-time-picker
                                            v-if="menuTimeBegin"
                                            v-model="date.beginTime"
                                            full-width
                                            @click:minute="$refs.menuTimeBegin.save(date.beginTime)"
                                        ></v-time-picker>
                                    </v-menu>
                                    <div class="error-msg" v-if="$v.date.beginTime.$error">*Required</div>
                                </div>
                            </div>
                            <div class="separation"></div>
                            <div class="date_time_ends">
                                <div class="date ends">
                                    <v-menu
                                        ref="menuDateEnd"
                                        v-model="menuDateEnd"
                                        :close-on-content-click="false"
                                        :return-value.sync="date.endDate"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div class="wrap_input_" v-bind="attrs" v-on="on">
                                                <input type="text" class="input-style-2 dropdown" :class="{'input_error': $v.date.endDate.$error}"
                                                       v-model="endDateFormatted"
                                                       :disabled="true"
                                                       placeholder="Ends">
                                            </div>

                                        </template>
                                        <v-date-picker
                                            v-model="date.endDate"
                                            :weekday-format="getDay"
                                            no-title
                                            scrollable
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="menuDateEnd = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.menuDateEnd.save(date.endDate)"
                                            >
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                    <div class="error-msg" v-if="$v.date.endDate.$error">*Required</div>
                                </div>
                                <div class="time ends">
                                    <v-menu
                                        ref="menuTimeEnd"
                                        v-model="menuTimeEnd"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        :return-value.sync="date.endTime"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div class="wrap_input_" v-bind="attrs" v-on="on">
                                                <input type="text" class="input-style-2 dropdown" :class="{'input_error': $v.date.endTime.$error}"
                                                       v-model="date.endTime"
                                                       :disabled="true"
                                                       placeholder="Time">
                                            </div>
                                        </template>
                                        <v-time-picker
                                            v-if="menuTimeEnd"
                                            v-model="date.endTime"
                                            full-width
                                            @click:minute="$refs.menuTimeEnd.save(date.endTime)"
                                        ></v-time-picker>
                                    </v-menu>
                                    <div class="error-msg" v-if="$v.date.endDate.$error">*Required</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Description -->
                    <div class="row_section">
                        <div class="_name">
                            Description
                        </div>
                        <div class="_data">
                            <!--                        <textarea rows="4" class="textarea-style-2" placeholder="About" v-model="description"></textarea>-->
                            <!--                        <div class="error-msg" v-if="$v.description.$invalid">*Required</div>-->

                            <v-textarea
                                :class="'textarea-style-2'"
                                solo
                                v-model="description"
                                label="About"
                                required
                                auto-grow
                            ></v-textarea>
                            <span class="error-msg v-textarea" v-if="$v.description.$error">*Required</span>
                        </div>



                    </div>

                    <!-- Photos -->
                    <div class="row_section">
                        <div class="_name">
                            Photos
                            <div class="hint list">
                                <span>10 Photos limit</span>
                                <span>JPEG, PNG only</span>
                                <span>10MB or less per file</span>
                                <span>Drag to change order</span>
                            </div>
                        </div>
                        <div class="_data photos">
                            <draggable class="uploaded_photo" v-model="uploadedPhoto" v-bind="dragOptions" @start="isDragging = true" @end="isDragging = false">

                                <div v-for="(photo, index) in uploadedPhoto" :key="dynamicPhotoKey + index">
                                    <img class="cover_select_img" :src="photo.url" :alt="photo.name" :ref="photo.lastModified">
                                    <button type="button" class="remove_photo" @click="removePhoto(index)"></button>
                                </div>

                                <div class="upload_photo" @click="selectPhoto">
                                    <input
                                        type="file"
                                        hidden
                                        multiple
                                        ref="select_photo"
                                        accept="image/png, image/jpeg"
                                        @change="handleFileUpload($event)">
                                    <span class="select_img">
                                    <span class="material-icons">add_circle</span>
                                    Upload
                                </span>

                                </div>

                            </draggable>
                        </div>
                    </div>

                    <!-- Tickets -->
                    <div class="row_section">
                        <div class="_name">
                            Tickets
                            <div class="hint">
                                <span>This section is optional</span>
                            </div>
                        </div>
                        <div class="_data tickets">

                            <!-- Create New Ticket -->
                            <div class="item_ticket">
                                <input type="text" class="input-style-2" placeholder="Ticket 1" v-model="ticketTitle">
                                <div class="ticket_inputs_row">
                                    <input type="text" class="input-style-2" placeholder="Price" v-on:keypress="numbersOnly" v-model="ticketPrice">
                                    <input type="text" class="input-style-2" placeholder="Link" v-model="ticketLink">
                                </div>
                                <textarea rows="4" class="textarea-style-2" placeholder="Description" v-model="ticketDescription"></textarea>
                                <button type="button" class="action_ticket add" @click="addTicket()">Add Ticket</button>
                            </div>


                            <!-- Tickets List -->
                            <div class="item_ticket" v-for="(itemTicket, index) of tickets" :key="index">
                                <input type="text" class="input-style-2" placeholder="Ticket 1" v-model="itemTicket.title" disabled>
                                <div class="ticket_inputs_row">
                                    <input type="text" class="input-style-2" placeholder="Price" v-model="itemTicket.price" disabled>
                                    <input type="text" class="input-style-2" placeholder="Link" v-model="itemTicket.link" disabled>
                                </div>
                                <textarea rows="4" class="textarea-style-2" placeholder="Description" v-model="itemTicket.description" disabled></textarea>
                                <button type="button" class="action_ticket remove" @click="removeTicket(index)">Remove Ticket</button>
                            </div>

                        </div>
                    </div>

                </section>
                <div class="wrap_actions">
                    <button type="button" class="tetriatary_btn x_large" v-ripple @click="saveNewEvent('save-exit')">Save & exit</button>
                    <button type="button" class="secondary_btn x_large" v-ripple @click="saveNewEvent('review')">Review</button>
                </div>

                <v-progress-circular
                    v-if="createLoading"
                    :size="80"
                    :width="2"
                    color="#20C4F5"
                    indeterminate
                ></v-progress-circular>

            </v-container>
        </div>
    </eventsLayout>
</template>


<script>
import {mapActions, mapGetters} from "vuex";

import eventsLayout from "@/layouts/eventsLayout.vue";
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import {required} from "vuelidate/lib/validators";
import moment from 'moment';
import draggable from 'vuedraggable';

export default {
    name: 'createEvent',
    components: {
        eventsLayout,
        draggable,
        VueGoogleAutocomplete
    },
    data: (vm) => ({
        afterSave: '',

        name: '',
        genres: [],
        settings: [],
        location: {},
        description: '',

        // dates
        date: {
            beginDate: '',
            beginTime: '',
            endDate: '',
            endTime: ''
        },
        beginDateFormatted: '',
        endDateFormatted: '',

        menuDateBegin: false,
        menuTimeBegin: false,
        menuDateEnd: false,
        menuTimeEnd: false,
        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],


        // photos
        uploadedPhoto: [],
        uploadedPhotoLinks: [],
        dynamicPhotoKey: 1,

        // Tickets
        ticketTitle: '',
        ticketPrice: '',
        ticketLink: '',
        ticketDescription: '',

        tickets: [],
        createLoading: false
    }),
    validations: {
        name: {
            required,
        },
        genres: {
            required,
        },
        settings: {
            required,
        },
        location: {
            required
        },
        date: {
            beginDate: {
                required,
            },
            beginTime: {
                required,
            },
            endDate: {
                required,
            },
            endTime: {
                required,
            }
        },
        description: {
            required
        },
        uploadedPhoto: {
            required
        }
    },
    watch: {
        'date.beginDate'() {
            this.beginDateFormatted = this.formatDate(this.date.beginDate);
        },
        'date.endDate'() {
            this.endDateFormatted = this.formatDate(this.date.endDate);
        }
    },
    updated() {
        this.moment = moment;
    },
    computed: {
        ...mapGetters(['FILE_URL']),
        ...mapGetters({
            tempCreatedEventID: 'events/TEMP_CREATED_EVENT_ID',
        }),
        dragOptions() {
            return {
                animation: 0,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        ...mapActions(['ADD_FILE']),
        ...mapActions({
            ADD_EVENT: 'events/ADD_EVENT'
        }),

        // location
        keypressLocation(event) {
            this.location = event.target.value;
        },
        getAddressData: function (addressData, placeResultData) {
            this.location = addressData;
        },
        numbersOnly(evt) {
            evt = (evt) ? evt : window.event;
            const charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        // datePickers
        getDay(date) {
            let i = new Date(date).getDay(date);
            return this.daysOfWeek[i];
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split('-');
            return `${month}/${day}/${year}`;
        },

        // photos
        selectPhoto() {
            this.$refs.select_photo.click();
        },
        handleFileUpload(event) {
            let selectedFiles = event.target.files;
            if (selectedFiles) {
                let _self = this;
                for (let img = 0; img < selectedFiles.length; img++) {
                    let fileReader = new FileReader();
                    fileReader.onload = function (event) {
                        selectedFiles[img].url = event.target.result;
                        _self.uploadedPhoto.push(selectedFiles[img]);
                    }
                    fileReader.readAsDataURL(selectedFiles[img]);
                }
                this.dynamicPhotoKey += 1;
                console.log('this.uploadedPhoto after', this.uploadedPhoto);
            }
        },
        removePhoto(index) {
            this.uploadedPhoto.splice(index, 1);
        },

        // tickets
        addTicket() {
            if (this.ticketTitle !== '' && this.ticketPrice !== '' && this.ticketLink !== '' && this.ticketDescription !== '') {
                this.tickets.push({
                    title: this.ticketTitle,
                    price: this.ticketPrice,
                    link: this.ticketLink,
                    description: this.ticketDescription,
                });
                this.ticketTitle = '';
                this.ticketPrice = '';
                this.ticketLink = '';
                this.ticketDescription = '';
            }
        },
        removeTicket(index) {
            this.tickets.splice(index, 1);
        },

        async saveNewEvent(save) {
            this.createLoading = true;
            this.afterSave = save;

            this.addTicket();
            this.$v.$touch();

            const params = {
                title: this.name,
                location: JSON.stringify(this.location),
                dateStart: moment(this.date.beginDate + " " + this.date.beginTime).format(),
                dateEnd: moment(this.date.endDate + " " + this.date.endTime).format(),
                description: this.description,
                genre: this.genres,
                setting: this.settings,
            }

            if (this.tickets.length) {
                params['tickets'] = this.tickets;
            }

            if (this.uploadedPhoto.length) {
                for (let item of this.uploadedPhoto) {
                    let formData = new FormData();
                    formData.set('file', item);
                    await this.ADD_FILE(formData).then(() => {
                        this.uploadedPhotoLinks.push(this.FILE_URL);
                    });
                }
                params['images'] = this.uploadedPhotoLinks;
            }



            this.ADD_EVENT(params)
                .then(() => {
                    if (this.afterSave === 'save-exit') {
                        this.$router.push({name: 'explore' });
                    }
                    if (this.afterSave === 'preview') {
                        this.$router.push({name: 'eventDetails', params: {id: this.tempCreatedEventID}});
                    }
                })
                .catch(err => console.error(`saveNewEvent, ${err}`))
                .finally(() => {
                    this.createLoading = false;
                })
        },

    }
}
</script>

<style scoped>

</style>
